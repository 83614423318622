<template>
  <section id="notifications-table">
    <b-row class="align-items-center">
      <b-col>
        <b-form-input
          v-model="search"
          :placeholder="$t('Search')"
          @change="onKeyUp"
        />
      </b-col>
      <b-col
        md="1"
        class="d-flex justify-content-end"
      >
        <b-button
          variant="primary"
          @click="onKeyUp"
        >
          {{ $t('Search') }}
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <b-card no-body>
          <b-table
            ref="selectableTable"
            :busy="show"
            striped
            hover
            :no-border-collapse="true"
            :selectable="true"
            :items="notifications"
            select-mode="single"
            :fields="columnsNotifications"
            responsive
            @row-selected="EditNotication"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle" />
                <strong>{{ $t('load') }}...</strong>
              </div>
            </template>
            <template #cell(name)="data">
              <div class="d-flex align-items-center">
                <span>
                  {{ data.item.node.name }}
                </span>
                <b-button
                  v-if="isSended(data.item.node)==1"
                  variant="outline-warning"
                  disabled
                  size="sm"
                  class="d-flex align-items-center ml-1"
                >
                  <feather-icon
                    icon="AlertTriangleIcon"
                    class="text-warning mr-25"
                    size="12"
                  />
                  <span class="text-white">
                    {{ $t('pendingDelivery') }}
                  </span>
                </b-button>
              </div>
            </template>
            <template #cell(type)="data">
              <div class="d-flex align-items-center">
                {{ typeNotifications[data.item.node.notificationType] }}
              </div>
            </template>
            <template #cell(sendDate)="data">
              <div class="d-flex align-items-center">
                {{ formatearFechaHora(data.item.node.sendDate) }}
              </div>
            </template>
            <template #cell(sendType)="data">
              <div class="d-flex align-items-center">
                {{ typeSended[data.item.node.sendType] }}
              </div>
            </template>
            <template #cell(target)="data">
              <div class="d-flex align-items-center">
                {{ typeTarget[data.item.node.target] }}
              </div>
            </template>
            <template #cell(sended)="data">
              <div class="d-flex align-items-center">
                <b-badge
                  :variant="isSended(data.item.node) == 0 ? 'success' : (isSended(data.item.node) == 1 ? 'warning' : 'danger')"
                >
                  {{ isSended(data.item.node) == 0 ? $t('Enviado') : (isSended(data.item.node) == 1 ? $t('pending') :
                    $t('noSend')) }}
                  <!-- {{ data.item.node.sendedAt ? $t('Enviado') : $t('Sin enviar') }} -->
                </b-badge>
              </div>
            </template>
            <template #cell(user)="data">
              <div class="d-flex align-items-center">
                {{ isEmpty(data.item.node.user) ? $t('Sin usuario') : data.item.node.user.username }}
              </div>
            </template>
            <template #cell(successMessages)="data">
              <div class="d-flex align-items-center">
                {{ data.item.node.successMessages }}
              </div>
            </template>
            <template #cell(openedMessages)="data">
              <div class="d-flex align-items-center">
                {{ data.item.node.openedMessages }}
              </div>
            </template>
            <template #cell(actions)="">
              <div class="d-flex align-items-center">
                <b-dropdown
                  variant="link"
                  no-caret
                  toggle-class="p-0"
                  dropleft
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="17"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item @click="EditNotication">
                    <div class="d-flex">
                      <feather-icon
                        icon="Edit2Icon"
                        class=" mr-2"
                        size="18"
                      />
                      {{ $t('dataGeneric.edit') }}
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </b-table>
          <div class="d-flex align-items-center justify-content-between m-1">
            <div class="d-flex align-items-center">
              <b-form-select
                v-model="perPage"
                style="width: fit-content;"
                :options="[
                  { text: '10 items', value: 10 },
                  { text: '20 items', value: 20 },
                  { text: '30 items', value: 30 },
                ]"
                @change="getNotifications"
              />
              <span class="text-muted ml-1">{{ $t('table.foot', { from: from, to: to, total: rows }) }}
              </span>
            </div>
            <b-pagination
              v-if="rows > perPage"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              size="md"
              @input="getNotifications()"
            />
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      centered
      size="lg"
      title="Avatar"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <validation-observer
        id="modal_notifications"
        ref="notification"
        tag="form"
      >
        <b-overlay
          :show="show"
          variant="transparent"
        >
          <b-row>
            <b-col>
              <validation-provider
                v-slot="{ errors }"
                name="nameNotification"
                rules="required"
              >
                <b-form-group
                  :label="$t('dataGeneric.name') + ' *'"
                  :invalid-feedback="$t('required')"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-input
                    id="name"
                    v-model="nameNotification"
                    :disabled="!editModal"
                    :state="errors.length > 0 ? false : null"
                    maxlength="255"
                    autocomplete="new-password"
                    :placeholder="$t('firstName')"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <b-form-group :label="$t('title')">
                <b-form-input
                  id="title"
                  v-model="title"
                  :disabled="!editModal"
                  maxlength="255"
                  autocomplete="new-password"
                  :placeholder="$t('title')"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group :label="$t('tiposNot')">
                <b-form-select
                  id="typeSelected"
                  v-model="typeSelected"
                  :disabled="!editModal"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="typeNotifications"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="typeSelected == 'TEX'">
            <!-- TEXTO -->
            <b-col>
              <validation-provider
                v-slot="{ errors }"
                name="description"
                rules="required"
              >
                <b-form-group
                  :label="$t('notificationPush.text') + ' *'"
                  :invalid-feedback="$t('required')"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-textarea
                    id="description"
                    v-model="description"
                    :disabled="!editModal"
                    rows="5"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('notificationPush.text')"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row v-if="typeSelected == 'URL'">
            <!-- URL -->
            <b-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                name="url"
                rules="required|url"
              >
                <b-form-group
                  :label="$t('notificationPush.url') + ' *'"
                  :state="errors.length > 0 ? false : null"
                  :invalid-feedback="$t('required')"
                >
                  <b-form-input
                    id="url"
                    v-model="urlNotification"
                    :disabled="!editModal"
                    maxlength="255"
                    :state="errors.length > 0 ? false : null"
                    placeholder="https://www.ejemplo.com"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                v-slot="{ errors }"
                name="description"
                rules="required"
              >
                <b-form-group
                  :label="$t('notificationPush.text') + ' *'"
                  :invalid-feedback="$t('required')"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-textarea
                    id="description"
                    v-model="description"
                    :disabled="!editModal"
                    rows="5"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('notificationPush.text')"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row v-if="typeSelected == 'CON'">
            <b-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                name="content"
                rules="required"
              >
                <b-form-group
                  label="Contenido *"
                  :invalid-feedback="$t('required')"
                  :state="errors.length > 0 ? false : null"
                >
                  <div class="d-flex">
                    <b-form-input
                      id="content"
                      v-model="contentName"
                      :disabled="true"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Contenido"
                    />
                    <b-button
                      variant="primary"
                      :disabled="!editModal"
                      class="ml-2"
                      @click="$refs['cont-modal'].show()"
                    >
                      {{ $t('select') }}
                    </b-button>
                  </div>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                v-slot="{ errors }"
                name="description"
                rules="required"
              >
                <b-form-group
                  :label="$t('notificationPush.text') + ' *'"
                  :invalid-feedback="$t('required')"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-textarea
                    id="description"
                    v-model="description"
                    :disabled="!editModal"
                    rows="5"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('notificationPush.text')"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row v-if="typeSelected == 'CAC'">
            <b-col>
              <validation-provider
                v-slot="{ errors }"
                name="category"
                rules="required"
              >
                <b-form-group
                  :label="$t('catCont') + ' *'"
                  :invalid-feedback="$t('required')"
                  :state="errors.length > 0 ? false : null"
                >
                  <div class="d-flex">
                    <b-form-input
                      id="category"
                      v-model="categoryName"
                      :disabled="!editModal"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('catCont')"
                    />
                    <b-button
                      variant="primary"
                      :disabled="!editModal"
                      class="ml-2"
                      @click="$refs['cat-modal'].show()"
                    >
                      {{ $t('select') }}
                    </b-button>
                  </div>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                name="description"
                rules="required"
              >
                <b-form-group
                  :label="$t('notificationPush.text') + ' *'"
                  :invalid-feedback="$t('required')"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-textarea
                    id="description"
                    v-model="description"
                    :disabled="!editModal"
                    :state="errors.length > 0 ? false : null"
                    rows="5"
                    :placeholder="$t('notificationPush.text')"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row v-if="typeSelected == 'CAP'">
            <b-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                name="categoryProd"
                rules="required"
              >
                <b-form-group
                  :label="$t('slider.productCategory') + ' *'"
                  :invalid-feedback="$t('required')"
                  :state="errors.length > 0 ? false : null"
                >
                  <div class="d-flex">
                    <b-form-input
                      id="categoryProd"
                      v-model="categoryProdName"
                      :disabled="!editModal"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('slider.productCategory')"
                    />
                    <b-button
                      variant="primary"
                      :disabled="!editModal"
                      class="ml-2"
                      @click="$refs['cat-product-modal'].show()"
                    >
                      {{ $t('select') }}
                    </b-button>
                  </div>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                v-slot="{ errors }"
                name="description"
                rules="required"
              >
                <b-form-group
                  :label="$t('notificationPush.text') + ' *'"
                  :invalid-feedback="$t('required')"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-textarea
                    id="description"
                    v-model="description"
                    :disabled="!editModal"
                    :state="errors.length > 0 ? false : null"
                    rows="5"
                    :placeholder="$t('notificationPush.text')"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row v-if="typeSelected == 'PRO'">
            <b-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                name="product"
                rules="required"
              >
                <b-form-group
                  :label="$t('producto') + ' *'"
                  :invalid-feedback="$t('required')"
                  :state="errors.length > 0 ? false : null"
                >
                  <div class="d-flex">
                    <b-form-input
                      id="product"
                      v-model="productName"
                      :disabled="!editModal"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('producto')"
                    />
                    <b-button
                      variant="primary"
                      :disabled="!editModal"
                      class="ml-2"
                      @click="$refs['product-modal'].show()"
                    >
                      {{ $t('select') }}
                    </b-button>
                  </div>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                v-slot="{ errors }"
                name="description"
                rules="required"
              >
                <b-form-group
                  :label="$t('notificationPush.text') + ' *'"
                  :invalid-feedback="$t('required')"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-textarea
                    id="description"
                    v-model="description"
                    :disabled="!editModal"
                    rows="5"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('notificationPush.text')"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group :label="$t('dispositivos')">
                <b-form-select
                  id="target"
                  v-model="target"
                  :disabled="!editModal"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="typeTarget"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group :label="$t('typeSends')">
                <b-form-select
                  id="sendType"
                  v-model="sendType"
                  :disabled="!editModal"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="typeSended"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                v-if="sendType !== 'AUTO'"
                :label="$t('dateSend')"
              >
                <flat-pickr
                  id="example-datepicker"
                  v-model="sendDate"
                  :disabled="!editModal"
                  class="form-control"
                  :placeholder="$t('dateSend')"
                  :config="{
                    enableTime: true,
                    enableSeconds: true,
                    dateFormat: 'Y-m-d H:i:S',
                    minDate: minDate,
                  }"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group :label="$t('sendToUsers')">
            <b-row>
              <b-col>
                <b-form-select
                  v-model="userTarget"
                  :disabled="!editModal"
                  :options="userTargetOptions"
                />
              </b-col>
            </b-row>
          </b-form-group>
          <b-row class="mb-2">
            <b-col
              md="6"
              @dragover="event => event.preventDefault()"
              @drop="editModal ? addFiles('imageData') : ''"
            >
              <label class="d-flex justify-content-between">
                {{ $t('Image') }}
              </label>
              <b-form-group
                class="
                            dropContainer
                            mb-2
                            mr-1
                            embed-responsive embed-responsive-16by9"
                @dragenter="event => event.preventDefault()"
              >
                <div
                  :class="editModal ? 'cursor-pointer base-image-input embed-responsive-item' : 'cursor-alias base-image-input embed-responsive-item'"
                  :style="{
                    background: `${imageData == null && imageSelected
                      ? `url(${imageDataSrc
                      }) center/contain no-repeat,white center/contain no-repeat`
                      : ''
                    }`
                  }"
                  @click="editModal ? chooseImage('fileInput') : ''"
                >
                  <span
                    v-if="!imageSelected"
                    class="placeholder embed-responsive-item"
                  >
                    <feather-icon
                      icon="ImageIcon"
                      size="80"
                    />
                    <div v-if="editModal">
                      {{ $t("media.dropFilesToCard") }}
                    </div>
                  </span>
                  <b-img
                    v-else
                    :src="imageDataSrc"
                    class="mb-3 embed-responsive-item"
                    fluid
                    block
                    rounded
                  />
                  <b-form-file
                    id="fileInput"
                    v-model="imageData"
                    accept="image/*"
                    class="mr-1"
                    style="display: none"
                  />
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-overlay>
      </validation-observer>
      <template #modal-footer="{ ok, cancel }">
        <b-overlay
          :show="show"
          variant="transparent"
          class="d-flex"
          rounded="sm"
        >
          <template #overlay>
            <div class="d-flex align-items-center">
              <b-spinner
                small
                type="grow"
                variant="secondary"
              />
              <b-spinner
                type="grow"
                variant="dark"
              />
              <b-spinner
                small
                type="grow"
                variant="secondary"
              />
              <span class="sr-only">{{ $t('wait') }}...</span>
            </div>
          </template>
          <b-button
            v-if="editModal"
            variant="danger"
            @click="cancel()"
          >
            {{ $t('dataGeneric.cancel') }}
          </b-button>
          <b-button
            variant="primary"
            class="ml-1"
            @click="ok()"
          >
            <span v-if="editModal">
              {{ $t('dataGeneric.save') }}
            </span>
            <span v-else>
              {{ $t('next') }}
            </span>
          </b-button>
        </b-overlay>
      </template>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTable,
  BFormFile,
  BFormTextarea,
  BImg,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BOverlay,
  BSpinner,
} from 'bootstrap-vue'
import {
  showToast, base64Encode, messageError, isEmpty,
  formateDateTime,
} from '@/store/functions'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import { required, url } from '@validations'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import axios from '@axios'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTable,
    BFormFile,
    BFormTextarea,
    BImg,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BOverlay,
    ValidationProvider,
    BSpinner,
    flatPickr,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    origin: {
      type: String,
      default: 'CMS',
    },
  },
  data() {
    return {
      disabled: true,
      formateDateTime,
      notificationText: null,
      id: null,
      url,
      currentPage: 1,
      perPage: 10,
      showUsers: false,
      urlLink: null,
      rows: 0,
      isLoading: false,
      disabledDemo: true,
      search: '',
      hasMore: false,
      isUrl: false,
      from: 0,
      to: 0,
      columnsNotifications: [
        { key: 'name', label: this.$t('firstName') },
        { key: 'type', label: this.$t('code.type') },
        { key: 'sendDate', label: this.$t('dateSend') },
        { key: 'sendType', label: this.$t('envio') },
        { key: 'target', label: this.$t('dispositivos') },
        { key: 'sended', label: this.$t('Enviado') },
        { key: 'user', label: this.$t('Creador') },

      ],
      nameNotification: '',
      title: '',
      description: '',
      urlNotification: '',
      sendDate: null,
      contentName: null,
      userTarget: 'ALL',
      sendDevices: false,
      contentId: null,
      image: null,
      target: 'ALL',
      categoryName: null,
      categoryId: null,
      categoryProdName: null,
      categoryProdId: null,
      productName: null,
      productId: null,
      required,
      userData: getUserData(),
      imageDataSrc: '',
      imageSelected: false,
      imageData: null,
      minDate: null,
      typeSelected: 'TEX',
      sendType: 'AUTO',
      typeNotifications: {
        TEX: this.$t('resources.scenarioText'),
        URL: 'URL',
        CON: this.$t('contents.content'),
        CAC: this.$t('purchases.category'),
        CAP: this.$t('slider.productCategory'),
        PRO: this.$t('producto'),
        WEB: 'Web',
      },
      typeSended: {
        AUTO: this.$t('interactivities.auto'),
        CRON: this.$t('programado'),
      },
      typeTarget: {
        ALL: this.$t('Todos'),
        WEB: 'Web',
        IOS: 'iOS',
        AND: 'Android',
      },
      userTargetOptions: {
        ALL: this.$t('allUser'),
        REG: this.$t('register'),
        UNR: this.$t('noRegister'),
      },
      platformId: getUserData().profile.client.id,
      categorySelectorTable: [
        { key: 'image', label: this.$t('purchases.category') },
        { key: 'name', label: this.$t('dataGeneric.name') },
      ],
      show: false,
      /* ------ */
      notifications: [],
      editModal: false,
      items: [],
      selected: [],
    }
  },
  watch: {
    imageData(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.imageSelected = true
              this.imageDataSrc = value
            })
            .catch(() => {
              this.imageDataSrc = null
            })
        } else {
          this.imageDataSrc = null
        }
      }
    },
    notificationText() {
      if (!this.showUsers) {
        if (
          this.notificationText != null
          && this.notificationText.trim() !== ''
        ) {
          this.disabled = false
          this.disabledDemo = false
        } else {
          this.disabled = true
          this.disabledDemo = true
        }
      } else if (
        this.notificationText != null
        && this.notificationText.trim() !== ''
      ) {
        if (this.selected.length === 0) { this.disabled = true } else { this.disabled = false }
      } else {
        this.disabled = true
      }
    },
    selected() {
      if (!this.showUsers) {
        if (
          this.notificationText != null
          && this.notificationText.trim() !== ''
        ) {
          this.disabled = false
          this.disabledDemo = false
        } else {
          this.disabled = true
          this.disabledDemo = true
        }
      } else if (
        this.notificationText != null
        && this.notificationText.trim() !== ''
      ) {
        if (this.selected.length === 0) { this.disabled = true } else { this.disabled = false }
      } else {
        this.disabled = true
      }
    },
    showUsers() {
      if (!this.showUsers) {
        if (
          this.notificationText != null
          && this.notificationText.trim() !== ''
        ) {
          this.disabled = false
          this.disabledDemo = false
        } else {
          this.disabled = true
          this.disabledDemo = true
        }
      } else {
        this.disabledDemo = true
        if (
          this.notificationText != null
          && this.notificationText.trim() !== ''
        ) {
          if (this.selected.length === 0) { this.disabled = true } else this.disabled = false
        } else {
          this.disabled = true
        }
      }
    },
  },
  mounted() {
    const fechaActual = new Date()
    fechaActual.setTime(fechaActual.getTime() + 10 * 60 * 1000)
    this.minDate = fechaActual

    this.getNotifications()
  },
  methods: {
    isEmpty,
    EditNotication(data) {
      this.$router.push({ name: 'edit-notifications', params: { id: data[0].node.id } })
    },
    isSended(node) {
      const send = !isEmpty(node.sendedAt)
      const { sendDate, sendType } = node
      const now = new Date()
      const dateCron = new Date(sendDate)
      if (send) return 0 // Enviado
      if (sendType === 'CRON') {
        if (dateCron > now) return 1 // Pendiente
      }
      return 2 // No enviado
    },
    getNotifications() {
      this.show = true
      const query = `
        {
          allNotificationPush(
            name:"${this.search}",
            client:"${this.userData.profile.client.id}",
            first:${this.perPage},
            orderBy:"-send_date",
            offset:${this.perPage * (this.currentPage - 1)},
            createdFrom:"${this.origin}"
          ) {
            totalCount
            edges {
              node {
                id
                notificationType
                sendedAt
                sendType
                user {
                  id
                  username
                }
                title
                description
                sendDate
                userTarget
                name
                target
                successMessages
                openedMessages
                image
                resource                        
              }
            }
          }
        }
      `

      axios
        .post('', { query })
        .then(result => {
          messageError(result, this)

          this.notifications = result.data.data.allNotificationPush.edges
          this.rows = result.data.data.allNotificationPush.totalCount
          this.from = this.perPage * (this.currentPage - 1) + (this.notifications.length ? 1 : 0)
          this.to = this.perPage * (this.currentPage - 1) + this.notifications.length
          this.show = false
        })
        .catch(error => {
          console.log(error)
        })
    },
    getNotificationById(node) {
      this.show = true
      this.nameNotification = node.name

      this.imageSelected = false
      this.imageDataSrc = ''
      this.imageData = null
      this.imageSelected = node.image !== ''
      this.imageDataSrc = this.imageSelected ? node.image : ''
      this.userTarget = node.userTarget
      this.title = node.title
      this.typeSelected = node.notificationType
      this.description = node.description
      this.id = node.id
      this.sendDate = node.sendDate
      this.urlNotification = node.resource
      const { typeId } = node
      this.sendType = node.sendType
      if (this.typeSelected !== 'TEX' && this.typeSelected !== 'URL' && this.typeSelected !== 'WEB'
        && this.typeSelected !== 'RES' && this.typeSelected !== 'APP') { this.getTypeId(this.typeSelected, typeId) } else {
        this.show = false
        this.$refs.modal.show()
      }
    },
    getTypeId(type, id) {
      let query = ''
      switch (type) {
        case 'CON':
          query = `{
            allContents(id:"${id}") {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }`

          break
        case 'CAC':
          query = `{
          allCategories(id:"${id}") {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }`
          break
        case 'CAP':
          query = `{
          allCategoryShop(id:"${id}") {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }`
          break
        case 'PRO':
          query = `{
          allProducts(id:"${id}") {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }`
          break

        default:
          break
      }
      axios
        .post('', { query }).then(result => {
          messageError(result, this)
          let content = null
          let categories = null
          let categoriesShop = null
          let products = null
          switch (type) {
            case 'CON':
              content = result.data.data.allContents.edges[0].node
              this.contentId = content.id
              this.contentName = content.name

              break
            case 'CAC':

              categories = result.data.data.allCategories.edges[0].node
              this.categoryId = categories.id
              this.categoryName = categories.name
              break
            case 'CAP':

              categoriesShop = result.data.data.allCategoryShop.edges[0].node
              this.categoryProdId = categoriesShop.id
              this.categoryName = categoriesShop.name
              break
            case 'PRO':

              products = result.data.data.allProducts.edges[0].node
              this.productId = products.id
              this.productName = products.name
              break

            default:
              break
          }
          this.show = false
          this.$refs.modal.show()
        }).catch(() => {

        })
    },
    addFiles(type) {
      // eslint-disable-next-line no-restricted-globals
      event.dataTransfer.files.forEach(element => {
        switch (type) {
          case 'imageData':
            this.imageData = element
            break
          default:
            break
        }
      })
      // eslint-disable-next-line no-restricted-globals
      event.preventDefault()
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.editModal) { this.validationForm() } else {
        this.$nextTick(() => {
          this.$bvModal.hide('modal-prevent-closing')
        })
      }
    },
    resetModal() {
      this.nameNotification = ''
      this.title = ''
      this.typeSelected = ''
      this.description = ''
      this.id = ''
      this.sendDate = ''
      this.urlNotification = ''
      this.contentId = ''
      this.contentName = ''
      this.categoryId = ''
      this.categoryName = ''
      this.categoryProdId = ''
      this.categoryName = ''
      this.productId = ''
      this.productName = ''
      this.sendType = ''
      this.imageSelected = false
      this.imageDataSrc = ''
      this.imageData = null

      this.show = false
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.notification.validate().then(success => {
          if (success) {
            resolve(true)
            this.disabled = false
            this.updatePushNotification()
          } else {
            this.disabled = false
            reject()
          }
        })
      })
    },
    chooseImage(imageClicked) {
      document.getElementById(imageClicked).click()
    },
    fechaFormato(value) {
      const fecha = new Date(value)
      return value != null ? fecha : value
    },
    formatearFechaHora(fechaISO) {
      if (fechaISO) {
        const fecha = new Date(fechaISO)
        const dia = fecha.getDate().toString().padStart(2, '0')
        const mes = (fecha.getMonth() + 1).toString().padStart(2, '0')
        const anio = fecha.getFullYear()
        const hora = fecha.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' })

        return `${dia}/${mes}/${anio} ${hora}`
      }
      return '--/--/---- --:--'
    },
    onKeyUp() {
      this.currentPage = 1
      this.getNotifications()
    },
    updatePushNotification() {
      this.show = true
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const query = `
        mutation (
          $name: String!,
          $title: String,
          $description: String!,
          $notificationType: NotificationPushNotificationTypeInput,
          $url: String,
          $sendType: NotificationPushSendTypeInput,
          $sendDate: DateTime,
          $target: NotificationPushTargetInput, 
          $userTarget:NotificationPushUserTargetInput,
          $user: ID!
        ) {
          updateNotificationPush (
            id:"${this.id}",
            input:{
              user: $user,
              name: $name,
              title: $title,
              sendType: $sendType,
              userTarget: $userTarget,
              sendDate: $sendDate,
              target: $target,
              description: $description,
              notificationType: $notificationType,
              url: $url
            }
          ) {
            notificationPush {
              id
              name
            }
          }
        }
      `
      const variables = {
        name: this.nameNotification,
        title: this.title,
        notificationType: this.typeSelected,
        url: this.urlNotification,
        description: this.description,
        target: this.target,
        sendDate: this.fechaFormato(this.sendDate),
        sendType: this.sendType,
        userTarget: this.userTarget,
        user: this.userData.id,
      }
      data.append('variables', JSON.stringify(variables))
      data.append('query', query)
      data.append('image', this.imageData)
      axios
        .post('', data, config)
        .then(result => {
          messageError(result, this)
          showToast(this.$t('success'), 1, this)
          this.show = false

          this.$nextTick(() => {
            this.$bvModal.hide('modal-prevent-closing')
            this.getNotifications()
          })
        })
        .catch(() => {
          showToast(this.$t('error'), 0, this)
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#modal_notifications .base-image-input {
  display: block;
  background-size: cover;
  background-position: center center;
}

#modal_notifications .placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

#modal_notifications .dropContainer {
  border: 3px dashed;
}

#modal_notifications .file-input {
  display: none;
}

#modal_notifications .modal {
  overflow-y: auto;
}

#modal_notifications .tamanio {

  width: auto;
  height: auto;
}
</style>
