<template>
  <section id="push-notifications">
    <b-row>
      <b-col cols="10">
        <h1>{{ $t("Notifications") }}</h1>
        <h5 class="text-primary">
          {{ $t("notificationPush.subtitle") }}
        </h5>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <b-tabs
          pills
          lazy
        >
          <!-- NOTIFICACIONES GENERADAS DESDE CMS -->
          <b-tab active>
            <template #title>
              <span>{{ $t("notificationPush.manual") }}</span>
            </template>
            <b-row>
              <b-col>
                <b-card>
                  <b-row class="mb-1">
                    <b-col style="align-self: center; text-align: -webkit-right;">
                      <b-button
                        style="max-width: fit-content;"
                        :to="{ name: 'create-notifications' }"
                        variant="primary"
                        class="d-flex"
                      >
                        <feather-icon
                          icon="PlusCircleIcon"
                          class="mr-50"
                        />
                        <span class="text-nowrap">{{ $t('creatNot') }}</span>
                      </b-button>
                    </b-col>
                  </b-row>
                  <notifications-table
                    origin="CMS"
                  />
                </b-card>
              </b-col>
            </b-row>
          </b-tab>
          <!-- NOTIFICACIONES GENERADAS DESDE INTERACTIVIDADES -->
          <b-tab>
            <template #title>
              <span>{{ $t("notificationPush.interactvty") }}</span>
            </template>
            <b-row>
              <b-col>
                <b-card>
                  <notifications-table
                    origin="INTERACTIVTY"
                  />
                </b-card>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import NotificationsTable from './NotificationsTable.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTabs,
    BTab,
    NotificationsTable,
  },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

</style>
